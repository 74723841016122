import React from "react"
import { graphql } from "gatsby"
import BlogPostChrome from "../../components/BlogPostChrome"
import NeuralStyleFlow from './NeuralStyleFlow'
import { findDOMNode } from "react-dom"
import SvgComponent from './neural_style_flow'
import Img from 'gatsby-image'
import Poster from './poster.png'
import Layout from './../../components/Layouts';
var d3 = require(`d3`)

export const frontmatter = {
    title: `Neural Style Transfer`,
    written: `2018-02-20`,
    updated: `2018-03-03`,
    layoutType: `post`,
    contentType: "blog",    
    path: `/neuralstyle/`,
    category: `Deep learning`,
    image: './poster.png',
    description: `Can computers learn to draw like Picasso, Vangogh, Monet or any other artist ? Gatys et al. introduce an algorithm that uses a Deep neural network to generate artistic images of high perceptual quality.`,
}

class NeuralStyleBase extends React.Component {
    constructor(props) {
      super(props)
      // console.log('NeuralStyleBase:', Poster);
    }
  
    render() {    
      console.log(this.props.data)  
      let data = this.props.data.markdownRemark
      let html = data.html
      let file = this.props.data.file

      const sunset300 = this.props.data.sunset.childImageSharp.resolutions
      const leonid_city = this.props.data.leonid_city.childImageSharp.resolutions
      const sunset_leonid_city = this.props.data.sunset_leonid_city.childImageSharp.resolutions

      const road = this.props.data.road.childImageSharp.resolutions
      const paint1 = this.props.data.paint1.childImageSharp.resolutions
      const road_paint1 = this.props.data.road_paint1.childImageSharp.resolutions

      const tree = this.props.data.tree.childImageSharp.resolutions
      const tree1 = this.props.data.tree1.childImageSharp.resolutions
      const tree_tree1 = this.props.data.tree_tree1.childImageSharp.resolutions

      const waterfall_c = this.props.data.waterfall_c.childImageSharp.resolutions
      const waterfall_s = this.props.data.waterfall_s.childImageSharp.resolutions
      const waterfall_g = this.props.data.waterfall_g.childImageSharp.resolutions

      const silo_c = this.props.data.silo_c.childImageSharp.resolutions
      const silo_s = this.props.data.silo_s.childImageSharp.resolutions
      const silo_g = this.props.data.silo_g.childImageSharp.resolutions
  
      return (
        <Layout data={this.props.data} location={this.props.location}>
        <BlogPostChrome {...this.props.data.javascriptFrontmatter}>
        <h1>Neural Style Transfer</h1>
        <article>
          <p>
            How can you teach a computer to draw like Picasso, Vangogh, Monet or any other artist ? 
            Is it even possible to encode the style of a painting and apply to an existing image ? 
            Gatys et al. introduce a novel concept<span className="citation-number">[^1]</span> that uses 
            Deep neural network to generate artistic images of high perceptual quality.
          </p>
          <p>
            The paper shows that it is possible to learn the style of a painting (style image) and use it to transform 
            the existing image (content) into an artistic version that appears similar to the painting. 
            In order to make the content image appear artistically similar to the style image, we need a metric that could capture 
            the perceptual style differences between the two. 
            {/* At the first thought, using pixel level differences between content and reference image comes to the mind but 
            If we use pixel level differences between the two images wouldn't help because it will just capture the content level 
            differences but not the style. (*back it up with example*) */}
            The paper introduces a new approach that uses the deep neural network to extract the content and style features 
            and jointly minimise the white noise image to make its contents similar to content image and style similar to style image.
            The level of content vs style can be tuned by tuning the amount of content or style to apply.           
          </p>
        
          <p>
            The paper uses images of painting as reference style image but we can experiment with other style images such as textures, 
            tile maps or even just another photograph.           
          </p>
          <div className="section" style={{margin: "0 auto"}}>
            <div className="">
                <div>
                    <NeuralStyleFlow />
                    <div className="has-text-centered ">
                        <span className="is-size-7 has-text-grey">Proposed network architecture</span>
                    </div>
                </div>
                <h2 className="is-title-3">Generated Samples</h2>
                <div>
                  <p>A few samples generated using neural style transfer (Content + Style = Generated)</p>
                  <br/>
                </div>
                <div className="tile is-ancestor"> 
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <p className="subtitle">Content</p>
                      <Img resolutions={sunset300} />
                    </div>
                  </div>
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <p className="subtitle">Style</p>
                      <Img resolutions={leonid_city} />
                    </div>
                  </div>
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <p className="subtitle">Generated</p>
                      <Img resolutions={sunset_leonid_city} />
                    </div>
                  </div>
                </div>
                <div className="tile is-ancestor"> 
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <Img resolutions={road} />
                    </div>
                  </div>
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <Img resolutions={paint1} />
                    </div>
                  </div>
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <Img resolutions={road_paint1} />
                    </div>
                  </div>
                </div>
                <div className="tile is-ancestor"> 
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <Img resolutions={tree} />
                    </div>
                  </div>
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <Img resolutions={tree1} />
                    </div>
                  </div>
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <Img resolutions={tree_tree1} />
                    </div>
                  </div>
                </div>                                
                <div className="tile is-ancestor"> 
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <Img resolutions={waterfall_c} />
                    </div>
                  </div>
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <Img resolutions={waterfall_s} />
                    </div>
                  </div>
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <Img resolutions={waterfall_g} />
                    </div>
                  </div>
                </div>                                
                <div className="tile is-ancestor"> 
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <Img resolutions={silo_c} />
                    </div>
                  </div>
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <Img resolutions={silo_s} />
                    </div>
                  </div>
                  <div className="tile is-parent">
                    <div className="tile is-child">
                      <Img resolutions={silo_g} />
                    </div>
                  </div>
                </div>                                
            </div>
          </div>
          <div className="section">
            <div className="">
              <h2 className="is-title-3">Implementation details</h2>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>          
         </article>
        </BlogPostChrome>
        </Layout>
      )
    }
  }
  
  export default NeuralStyleBase
  
  const styles = {}

  styles.row = {
    display: `flex`,
    flexWrap: `wrap`,
    margin: `8px -4px 1rem`,
  }

  // We want to keep this component mostly about the code
  //  so we write our explanation with markdown and manually pull it in here.
  //  Within the config, we loop all of the markdown and createPages. However,
  //  it will ignore any files appended with an _underscore. We can still manually
  //  query for it here, and get the transformed html though because remark transforms
  //  any markdown based node.
  export const pageQuery = graphql`
    query neuralstylequery($slug: String!) {
      markdownRemark(
        fields: { slug: { eq: "/2018-01-29-neuralstyle/_neuralstyle/" } }
      ) {
        html
      }
      javascriptFrontmatter(fields: { slug: { eq: $slug } }) {
        ...JSBlogPost_data
      }
      sunset: file(
        relativePath: {regex: "/sunset.jpg/"}) {
        childImageSharp {
          resolutions(width: 240, height: 160) {
            ...GatsbyImageSharpResolutions
          }
        }
      }      
      leonid_city: file(
        relativePath: {regex: "/leonid_city.jpeg/"}) {
        childImageSharp {
          resolutions(width: 240, height: 160) {
            ...GatsbyImageSharpResolutions
          }
        }
      }      
      sunset_leonid_city: file(
        relativePath: {regex: "/sunset_leonid_city_cw0.1_sw1.0_tv1e-07_100.png/"}) {
        childImageSharp {
          resolutions(width: 240, height: 160) {
            ...GatsbyImageSharpResolutions
          }
        }
      }      
      road: file(
        relativePath: {regex: "/road.jpg/"}) {
        childImageSharp {
          resolutions(width: 240, height: 160) {
            ...GatsbyImageSharpResolutions
          }
        }
      }      
      paint1: file(
        relativePath: {regex: "/paint1.jpg/"}) {
        childImageSharp {
          resolutions(width: 240, height: 160) {
            ...GatsbyImageSharpResolutions
          }
        }
      }      
      road_paint1: file(
        relativePath: {regex: "/road_paint1_cw0.1_sw1.0_tv1e-07_100.png/"}) {
        childImageSharp {
          resolutions(width: 240, height: 160) {
            ...GatsbyImageSharpResolutions
          }
        }
      }            
      tree: file(
        relativePath: {regex: "/tree.jpg/"}) {
        childImageSharp {
          resolutions(width: 240, height: 180) {
            ...GatsbyImageSharpResolutions
          }
        }
      }      
      tree1: file(
        relativePath: {regex: "/tree1.jpg/"}) {
        childImageSharp {
          resolutions(width: 240, height: 180) {
            ...GatsbyImageSharpResolutions
          }
        }
      }      
      tree_tree1: file(
        relativePath: {regex: "/tree_tree1_cw0.5_sw1.0_tv1e-07_100.png/"}) {
        childImageSharp {
          resolutions(width: 240, height: 180) {
            ...GatsbyImageSharpResolutions
          }
        }
      }            
      waterfall_c: file(
        relativePath: {regex: "/waterf_2.jpg/"}) {
        childImageSharp {
          resolutions(width: 240, height: 180) {
            ...GatsbyImageSharpResolutions
          }
        }
      }      
      waterfall_s: file(
        relativePath: {regex: "/waterfall.jpg/"}) {
        childImageSharp {
          resolutions(width: 240, height: 180) {
            ...GatsbyImageSharpResolutions
          }
        }
      }      
      waterfall_g: file(
        relativePath: {regex: "/waterfall_10000.0.jpg/"}) {
        childImageSharp {
          resolutions(width: 240, height: 180) {
            ...GatsbyImageSharpResolutions
          }
        }
      }            
      silo_c: file(
        relativePath: {regex: "/silopark.jpg/"}) {
        childImageSharp {
          resolutions(width: 240, height: 180) {
            ...GatsbyImageSharpResolutions
          }
        }
      }      
      silo_s: file(
        relativePath: {regex: "/udnie.jpg/"}) {
        childImageSharp {
          resolutions(width: 240, height: 180) {
            ...GatsbyImageSharpResolutions
          }
        }
      }      
      silo_g: file(
        relativePath: {regex: "/silopark_100000.0.jpg/"}) {
        childImageSharp {
          resolutions(width: 240, height: 180) {
            ...GatsbyImageSharpResolutions
          }
        }
      }            
    }
  `
  